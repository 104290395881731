<template>
  <div :class="[b(),className]"
       :style="styleSizeName"
       @mouseover="handleMouseOver"
       @mouseleave="handleMouseOut">
    <el-table :style="styleChartName"
              ref="table"
              @cell-click="cellClick"
              @row-click="rowClick"
              :data="dataChart"
              :height="height"
              :border="option.border"
              :cell-style="cellStyle"
              :row-style="rowStyle"
              :show-header="showHeader"
              :header-row-style="headerRowStyle"
              :header-cell-style="headerCellStyle">
      <el-table-column type="index"
                       label="#"
                       header-align="center"
                       align="center"
                       v-if="option.index"
                       width="60">
        <span slot-scope="{$index}">{{$index+1}}</span>
      </el-table-column>
      <template v-for="(item,index) in option.column">
        <el-table-column v-if="item.hide!==true"
                         show-overflow-tooltip
                         :key='index'
                         :prop="item.prop"
                         :label="item.label"
                         :width="item.width">
          <template slot-scope="{row}">
            <el-link type="danger"
                     plain
                     size="small"
                     v-if="item.type=='img'"
                     @click.stop="$ImagePreview([{url:row[item.prop]}], 0)">查看图片</el-link>
            <el-link type="success"
                     size="small"
                     v-else-if="item.type=='video'"
                     @click.stop="goVideo(row)">查看监控</el-link>
            <span v-else>{{row[item.prop]}}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <el-dialog :visible.sync="dialog.show"
               class="avue-dialog"
               append-to-body
               title="详情"
               width="50%">
      <el-form :model="dialog.form"
               label-suffix=":">
        <el-form-item :label="item.label"
                      v-for="(item,index) in headerList"
                      :key="index"
                      readonly>
          <el-button type="danger"
                     plain
                     v-if="item.type=='img'"
                     @click.stop="$ImagePreview([{url:item.value}], 0)">查看图片</el-button>
          <el-button type="success"
                     plain
                     v-else-if="item.type=='video'"
                     @click.stop="goVideo(dialog.form)">查看监控</el-button>
          <span v-else> {{item.value}}</span>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div v-if="video.box">
      <el-dialog class="avue-dialog"
                 destroy-on-close
                 append-to-body
                 :title="video.title"
                 :visible.sync="video.box"
                 width="80%">
        <avue-crud :option="video.option"
                   :data="video.data">
          <template slot="menu"
                    slot-scope="{row,size,type}">
            <el-button :size="size"
                       :type="type"
                       @click="goVideo(row)">查看监控</el-button>
          </template>
        </avue-crud>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import create from "../../create";
export default create({
  name: "table",
  data () {
    return {
      video: {
        title: '',
        box: false,
        form: {},
        data: [],
        option: {
          border: true,
          index: true,
          header: false,
          menuWidth: 80,
          editBtn: false,
          delBtn: false,
          column: [{
            label: '名称',
            prop: 'cameraName'
          }, {
            label: '类型',
            prop: 'cameraType'
          }, {
            label: '日期',
            width: 180,
            prop: 'createTime'
          }, {
            label: '平台',
            prop: 'videoPlatform',
            overHidden: true
          }, {
            label: '施工许可证',
            prop: 'wokeAllowName',
            overHidden: true
          }, {
            label: '在线状态',
            prop: 'onlineStatus'
          }, {
            label: '绑定状态',
            prop: 'bindStatus'
          }]
        }
      },
      dialog: {
        show: false,
        form: {}
      },
      headerHeight: '',
      height: '',
      scrollCheck: "",
    };
  },
  watch: {
    scrollSpeed () {
      this.setTime();
    },
    scroll: {
      handler () {
        this.setTime();
      },
    }
  },
  computed: {
    showHeader () {
      return this.option.showHeader
    },
    scrollTime () {
      return this.option.scrollTime
    },
    scrollSpeed () {
      return this.option.scrollSpeed || 1
    },
    scroll () {
      return this.option.scroll
    },
    cellHeight () {
      return parseInt((this.height - this.headerHeight) / this.option.count)
    },
    headerList () {
      let column = this.deepClone(this.option.column);
      column.forEach(ele => {
        ele.value = this.dialog.form[ele.prop]
      })
      return column
    }
  },
  props: {
    option: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  created () {
    this.$nextTick(() => {
      this.height = parseInt(this.$el.clientHeight);
      this.headerHeight = parseInt(this.$refs.table.$refs.headerWrapper.clientHeight)
      setTimeout(() => {
        this.setTime();
      }, this.scrollTime)
    })
  },
  methods: {
    goVideo (row) {
      //window.open('http://hnvjd.jyjzqy.com/jyvideo/HikVideo/videoControl.html?code=' + row.cameraCode + '&streamType=null')
      window.open(row.url)
    },
    showVideo (row) {
      this.video.title = `【${row['工程名称']}】视频监控列表`
      this.video.box = true
      this.video.form = row
      this.video.data = row.videoInfo || []
    },
    handleMouseOut () {
      this.setTime()
    },
    handleMouseOver () {
      clearInterval(this.scrollCheck);
    },
    cellClick (row, column, cell, event) {
      this.updateClick(row);
      this.clickFormatter && this.clickFormatter({
        type: column,
        item: row,
        data: this.dataChart
      }, this.getItemRefs());
    },
    setTime () {
      clearInterval(this.scrollCheck);
      this.height = parseInt(this.$el.clientHeight);
      this.headerHeight = parseInt(this.$refs.table.$refs.headerWrapper.clientHeight)
      const table = this.$refs.table
      const divData = table.bodyWrapper
      const speed = this.scrollSpeed
      let top = 0
      if (this.scroll) {
        this.scrollCheck = setInterval(() => {
          top = top + speed
          divData.scrollTop += speed;
          if (divData.clientHeight + divData.scrollTop == divData.scrollHeight) {
            divData.scrollTop = 0
          }
          if (top >= this.cellHeight && this.scrollTime) {
            divData.scrollTop = divData.scrollTop - (top - this.cellHeight)
            clearInterval(this.scrollCheck);
            setTimeout(() => {
              this.setTime()
            }, this.scrollTime)
          }
        }, 20)
      } else {
        divData.scrollTop = 0
      }
    },
    cellStyle ({ row, column, rowIndex, columnIndex }) {
      return {
        padding: 0,
        height: this.setPx(this.cellHeight),
        fontSize: this.setPx(this.option.bodyFontSize),
        color: this.option.bodyColor,
        textAlign: column.type == 'index' ? 'center' : this.option.bodyTextAlign,
        backgroundColor: rowIndex % 2 == 0 ? this.option.othColor : this.option.nthColor,
      }
    },
    rowStyle ({ rowIndex }) {
      return {
        backgroundColor: 'transparent'
      }
    },
    rowClick (row) {
      this.dialog.form = row;
      this.dialog.show = true
    },
    headerRowStyle () {
      return {
        backgroundColor: this.option.headerBackground
      }
    },
    headerCellStyle ({ row, column, rowIndex, columnIndex }) {
      return {
        fontSize: this.setPx(this.option.headerFontSize),
        backgroundColor: this.option.headerBackground,
        color: this.option.headerColor,
        textAlign: column.type == 'index' ? 'center' : this.option.headerTextAlign
      }
    }
  }
});
</script>

